$font-size-base: 16px !default;
$spacer-x: $font-size-base;
$spacer-y: $font-size-base;
$spacer-map: (
  0: 0,
  1: 0.25,
  2: 0.5,
  3: 0.75,
  4: 1,
  5: 1.5,
  6: 2
);

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-auto {
  margin-bottom: auto !important;
  margin-top: auto !important;
}

@each $i in 0 1 2 3 4 5 6 {
  $multiplier: map-get($spacer-map, $i);

  .mx-#{$i} {
    margin-left: $spacer-x * $multiplier !important;
    margin-right: $spacer-x * $multiplier !important;
  }

  .my-#{$i} {
    margin-bottom: $spacer-y * $multiplier !important;
    margin-top: $spacer-y * $multiplier !important;
  }

  .mb-#{$i} {
    margin-bottom: $spacer-y * $multiplier !important;
  }
  .mt-#{$i} {
    margin-top: $spacer-y * $multiplier !important;
  }
  .ml-#{$i} {
    margin-left: $spacer-x * $multiplier !important;
  }
  .mr-#{$i} {
    margin-right: $spacer-x * $multiplier !important;
  }

  .px-#{$i} {
    padding-left: $spacer-x * $multiplier !important;
    padding-right: $spacer-x * $multiplier !important;
  }

  .py-#{$i} {
    padding-bottom: $spacer-y * $multiplier !important;
    padding-top: $spacer-y * $multiplier !important;
  }

  .pb-#{$i} {
    padding-bottom: $spacer-y * $multiplier !important;
  }
  .pt-#{$i} {
    padding-top: $spacer-y * $multiplier !important;
  }
  .pl-#{$i} {
    padding-left: $spacer-x * $multiplier !important;
  }
  .pr-#{$i} {
    padding-right: $spacer-x * $multiplier !important;
  }
}

.font-weight-normal {
  font-weight: normal !important;
}

body {
  background-color: #000;
}

////// video.js
.vjs-theme-eventpop {
  --vjs-theme-eventpop--primary: #260176;
  --vjs-theme-eventpop--secondary: #fff;
}

.vjs-theme-eventpop {
  .vjs-big-play-button {
    width: 60px;
    height: 70px;
    background: none;
    background-repeat: no-repeat;
    background-position: 20%;
    background-size: 100px;
    border: none;
    top: 50%;
    left: 50%;
    margin-top: -30px;
    margin-left: -35px;
    mask-image: url('../images/fontawesome-icons/play.svg') !important;
    mask-repeat: no-repeat;
    mask-size: 48px;
    mask-position: center;
    background-color: #fff !important;

    &:hover {
      background-color: #513491 !important;
    }
    .vjs-icon-placeholder {
      display: none;
    }
  }

  .vjs-control-bar {
    background: rgba(0, 0, 0, 0.6);
    margin-bottom: 0;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 8px;
    height: 4em;
  }

  .vjs-play-control {
    font-size: 0.8em;

    .vjs-icon-placeholder:before {
      height: 1.5em;
      width: 1.5em;
      margin-top: 0.2em;
    }
  }

  .vjs-progress-control:hover .vjs-progress-holder {
    font-size: 1em;
  }

  .vjs-play-progress:before {
    display: none;
  }

  .vjs-progress-holder {
    border-radius: 0.2em;
    height: 0.25em;
    margin: 0;
  }

  .vjs-button > .vjs-icon-placeholder:before {
    line-height: 1.55;
  }

  .vjs-load-progress,
  .vjs-load-progress div,
  .vjs-play-progress {
    border-radius: 0.2em;
  }
}

.vjs-theme-eventpop
  .vjs-control:not(.vjs-disabled):not(.vjs-time-control):hover,
.video-js .vjs-control:focus:before,
.video-js .vjs-control:hover:before,
.video-js .vjs-control:focus {
  color: var(--vjs-theme-eventpop--secondary);
  outline: none;
}

.vjs-icon-pause:before,
.video-js .vjs-play-control.vjs-playing .vjs-icon-placeholder:before {
  content: '';
  mask-image: url('../images/fontawesome-icons/pause.svg');
  mask-repeat: no-repeat;
  mask-size: 1em;
  mask-position: 6px 2px;
  background-color: #fff;
}

.vjs-icon-fullscreen-enter:before,
.video-js .vjs-fullscreen-control .vjs-icon-placeholder:before {
  content: '';
  mask-image: url('../images/fontawesome-icons/expand-wide.svg');
  mask-repeat: no-repeat;
  mask-size: 1em;
  mask-position: 10px 4px;
  background-color: #fff;
}

.vjs-icon-play:before,
.video-js .vjs-play-control .vjs-icon-placeholder:before,
.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  content: '';
  mask-image: url('../images/fontawesome-icons/play.svg');
  mask-repeat: no-repeat;
  mask-size: 1em;
  mask-position: 6px 2px;
  background-color: #fff;
}

.vjs-icon-volume-mute:before,
.video-js .vjs-mute-control.vjs-vol-0 .vjs-icon-placeholder:before {
  content: '';
  mask-image: url('../images/fontawesome-icons/volume-mute.svg');
  mask-repeat: no-repeat;
  mask-size: 0.9em;
  mask-position: 6px 6px;
  background-color: #fff;
}

.vjs-icon-volume-low:before,
.video-js .vjs-mute-control.vjs-vol-1 .vjs-icon-placeholder:before {
  content: '';
  mask-image: url('../images/fontawesome-icons/volume-down.svg');
  mask-repeat: no-repeat;
  mask-size: 0.66em;
  mask-position: 6px 6px;
  background-color: #fff;
}

.vjs-icon-volume-mid:before,
.video-js .vjs-mute-control.vjs-vol-2 .vjs-icon-placeholder:before {
  content: '';
  mask-image: url('../images/fontawesome-icons/volume.svg');
  mask-repeat: no-repeat;
  mask-size: 0.85em;
  mask-position: 6px 6px;
  background-color: #fff;
}

.vjs-icon-volume-high:before,
.video-js .vjs-mute-control .vjs-icon-placeholder:before {
  content: '';
  mask-image: url('../images/fontawesome-icons/volume-up.svg');
  mask-repeat: no-repeat;
  mask-size: 1em;
  mask-position: 6px 6px;
  background-color: #fff;
}

.vjs-icon-fullscreen-exit:before,
.video-js.vjs-fullscreen .vjs-fullscreen-control .vjs-icon-placeholder:before {
  content: '';
  mask-image: url('../images/fontawesome-icons/compress-wide.svg');
  mask-repeat: no-repeat;
  mask-size: 1em;
  mask-position: 10px 4px;
  background-color: #fff;
}

.video-js .vjs-play-progress {
  background-color: #513491;
}

.video-js .vjs-load-progress {
  background-color: #b2b2b4;
}

.video-js .vjs-slider {
  background-color: rgba(127, 128, 130, 0.4);
}
