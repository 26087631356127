@font-face {
  font-family: 'Eventpop';
  src: url('./eventpop-font/Eventpop-Medium.eot');
  src: url('./eventpop-font/Eventpop-Medium.eot?#iefix') format('embedded-opentype'),
       url('./eventpop-font/Eventpop-Medium.woff2') format('woff2'),
       url('./eventpop-font/Eventpop-Medium.woff') format('woff'),
       url('./eventpop-font/Eventpop-Medium.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Eventpop';
  src: url('./eventpop-font/Eventpop-Medium.eot');
  src: url('./eventpop-font/Eventpop-Medium.eot?#iefix') format('embedded-opentype'),
       url('./eventpop-font/Eventpop-Medium.woff2') format('woff2'),
       url('./eventpop-font/Eventpop-Medium.woff') format('woff'),
       url('./eventpop-font/Eventpop-Medium.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Eventpop';
  src: url('./eventpop-font/Eventpop-Bold.eot');
  src: url('./eventpop-font/Eventpop-Bold.eot?#iefix') format('embedded-opentype'),
       url('./eventpop-font/Eventpop-Bold.woff2') format('woff2'),
       url('./eventpop-font/Eventpop-Bold.woff') format('woff'),
       url('./eventpop-font/Eventpop-Bold.ttf') format('truetype');
  font-weight: 700;
}